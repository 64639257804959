<template>
  <div class="General">
    <!-- header de titulo y acciones -->
    <headerLayaut subtitle="Horarios para la función">
      <v-btn
        color="primary"
        style="border-radius: 10px"
        class="mr-2"
        @click="dialog = true"
        >Cargar Excel</v-btn
      >
    </headerLayaut>

    <v-container fluid v-if="show">
      <v-form class="mt-6" ref="form" v-model="valid" lazy-validation>
        <v-row
          class="flex-center"
          v-for="(item, i) of schedule"
          :key="i"
          align="center"
          justify="center"
        >
          <v-col cols="3" class="flex-center pb-2">
            <v-switch
              label="Activo"
              v-model="item.active"
              :disabled="item.disabled"
            ></v-switch>
            <!-- <v-switch
            v-if="user.type == 'sudo'"
              label="Activo por ocho"
              v-model="item.ochoActive"
              class="ml-2"
            ></v-switch> -->
          </v-col>
          <v-col cols="3" class="flex-center ma-0 pa-0">
            <v-menu
              v-model="item.menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  label="Fecha"
                  persistent-hint
                  append-icon="fa-calendar-alt"
                  readonly
                  :rules="requerid"
                  filled
                  :disabled="item.disabled"
                  required
                  color="primary"
                  hide-details
                  rounded
                  v-model="item.date"
                  style="border-radius: 10px"
                  dense
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
                <!-- :disabled="!newItem.dateStart" -->
              </template>
              <v-date-picker
                no-title
                color="primary"
                @input="item.menu = false"
                :min="selectedEvent.dateStart"
                :max="selectedEvent.dateEnd"
                v-model="item.date"
              ></v-date-picker>
              <!-- v-model="newItem.dateEnd" -->
            </v-menu>
          </v-col>
          <v-col cols="1" class="flex-center ma-0 pa-0">
            <v-icon class="mb-8">fas fa-minus mt-4</v-icon>
          </v-col>
          <v-col cols="3" class="flex-center ma-0 pa-0">
            <!-- <v-text-field
              :rules="requerid"
              filled
              type="time"
              hide-details
              append-icon="fa-solid fa-clock"
              required
              v-model="item.time"
              label="Hora"
              style="border-radius: 10px"
              rounded
            >
            </v-text-field> -->
            <!--     :disabled="!schedule[`${day.active}`]" -->
            <v-select
              rounded
              :rules="requerid"
              label="Hora"
              style="border-radius: 10px"
              append-icon="fa-solid fa-clock"
              :disabled="item.disabled"
              filled
              :items="allTime"
              hide-details
              v-model="item.time"
            />
          </v-col>
          <v-col cols="2" class="">
            <v-btn
              :dark="$vuetify.theme.dark"
              fab
              class="mt-2 mr-2"
              v-if="schedule.length == i + 1"
              elevation="0"
              small
              @click="add"
              color="primary"
            >
              <v-icon dark> fas fa-plus </v-icon>
            </v-btn>

            <v-btn
              fab
              elevation="0"
              :dark="$vuetify.theme.dark"
              small
              :disabled="item.disabled"
              v-if="schedule.length != 1"
              class="mt-2"
              @click="remove(item, i)"
              style="border-radius: 10px"
              color="#aeaeae"
            >
              <v-icon dark> fas fa-times </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" class="ma-0 pa-1">
            <p
              class="text-caption text-center position-absolute"
              v-if="item.disabled"
            >
              El horario ya pertenece a una entrada
            </p>
            <v-divider v-if="i < schedule.length - 1"></v-divider>
          </v-col>
        </v-row>
      </v-form>
      <v-divider></v-divider>
      <v-row justify="end" class="my-12">
        <v-btn
          width="200px"
          height="50"
          color="primary"
          class="mr-6"
          @click="save"
          :disabled="!valid"
        >
          Guardar
        </v-btn>
      </v-row>
    </v-container>
    <v-container v-if="!show" class="flex-center loader--">
      <v-progress-circular
        :size="100"
        :width="8"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-container>

    <v-dialog v-model="dialog" scrollable width="500px" persistent>
      <v-card>
        <v-card-title>
          <v-btn
            style="right: 10px; top: 10px"
            icon
            color="primary"
            fab
            small
            absolute
            @click="dialog = false"
          >
            <v-icon dark>fa-times</v-icon>
          </v-btn>
          Cargar archivo
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-4">
          <v-file-input
            v-model="File"
            filled
            rounded
            prepend-icon="fa-solid fa-file-excel"
            style="border-radius: 10px"
            chips
            @change="uploadFile"
            label="Seleccione el archivo"
          ></v-file-input>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions class="actions-btn">
          <v-btn color="primary" text style="border-radius: 10px"
            >cancelar</v-btn
          >
          <v-btn color="primary" style="border-radius: 10px">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="matchDialog"
      v-if="matchDialog && File"
      scrollable
      width="600px"
      persistent
    >
      <matchFile
        @closed="matchDialog = false"
        @success="processFile"
        :file="File"
      />
    </v-dialog>
    <v-dialog v-model="dataValidDialog" width="500px" persistent scrollable>
      <v-card>
        <v-card-title> Validación de datos </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-4">
          <h3>
            Rango de fechas permitidas del evento <br />
            <span style="color: #fff"
              >{{ selectedEvent.dateStart }} - {{ selectedEvent.dateEnd }}</span
            >
          </h3>
          <v-row>
            <v-col cols="6">
              <h4>Fechas validas</h4>

              <ul class="UlStyle" style="background-color: #2fff0038">
                <li v-for="(i, index) in dataXLSValid" :key="index">
                  <span
                    ><b>{{ i.date }}</b></span
                  >
                </li>
              </ul></v-col
            >
            <v-col cols="6">
              <h4>Fechas no permitidas</h4>
              <ul class="UlStyle" style="background-color: #ff000045">
                <li v-for="(i, index) in dataXLSInvalid" :key="index">
                  <span
                    ><b>{{ i.date }}</b></span
                  >
                </li>
              </ul></v-col
            >
          </v-row>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions class="actions-btn">
          <v-btn
            color="primary"
            text
            style="border-radius: 10px"
            @click="cancelXLSValid"
            >cancelar</v-btn
          >
          <v-btn
            color="primary"
            style="border-radius: 10px"
            @click="addExcelfn"
            v-if="this.dataXLSValid.length > 0"
            >Agregar fechas validas</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import headerLayaut from "@/components/header-layaut.vue";
import matchFile from "@/components/matchFile.vue";
import readXlsxFile from "read-excel-file";
import store from "@/store/index.js";
import { mapActions, mapState } from "vuex";
import { db, fb } from "@/firebase";
export default {
  name: "FunctionSchedules",
  components: {
    headerLayaut,
    matchFile,
  },
  data() {
    return {
      valid: true,
      requerid: [(v) => !!v || "Campo requerido"],
      show: false,
      contSchedule: 0,
      File: null,
      dataXLSValid: [],
      dataXLSInvalid: [],
      matchDialog: false,
      tickets: [],
      dataValidDialog: false,
      dialog: false,
      allTime: [
        { text: "07:00 AM", value: "07:00" },
        { text: "07:30 AM", value: "07:30" },
        { text: "08:00 AM", value: "08:00" },
        { text: "08:30 AM", value: "08:30" },
        { text: "09:00 AM", value: "09:00" },
        { text: "09:30 AM", value: "09:30" },
        { text: "10:00 AM", value: "10:00" },
        { text: "10:30 AM", value: "10:30" },
        { text: "11:00 AM", value: "11:00" },
        { text: "11:30 AM", value: "11:30" },
        { text: "12:00 PM", value: "12:00" },
        { text: "12:30 PM", value: "12:30" },
        { text: "01:00 PM", value: "13:00" },
        { text: "01:30 PM", value: "13:30" },
        { text: "02:00 PM", value: "14:00" },
        { text: "02:30 PM", value: "14:30" },
        { text: "03:00 PM", value: "15:00" },
        { text: "03:30 PM", value: "15:30" },
        { text: "04:00 PM", value: "16:00" },
        { text: "04:30 PM", value: "16:30" },
        { text: "05:00 PM", value: "17:00" },
        { text: "05:30 PM", value: "17:30" },
        { text: "06:00 PM", value: "18:00" },
        { text: "06:30 PM", value: "18:30" },
        { text: "07:00 PM", value: "19:00" },
        { text: "07:30 PM", value: "19:30" },
        { text: "08:00 PM", value: "20:00" },
        { text: "08:30 PM", value: "20:30" },
        { text: "09:00 PM", value: "21:00" },
        { text: "09:30 PM", value: "21:30" },
        { text: "10:00 PM", value: "22:00" },
        { text: "10:30 PM", value: "22:30" },
        { text: "11:00 PM", value: "23:00" },
        { text: "11:30 PM", value: "23:30" },
        { text: "12:00 AM", value: "00:00" },
        { text: "12:30 AM", value: "00:30" },
        { text: "01:00 AM", value: "01:00" },
        { text: "01:30 AM", value: "01:30" },
        { text: "02:00 AM", value: "02:00" },
        { text: "02:30 AM", value: "02:30" },
        { text: "03:00 AM", value: "03:00" },
        { text: "03:30 AM", value: "03:30" },
        { text: "04:00 AM", value: "04:00" },
        { text: "04:30 AM", value: "04:30" },
        { text: "05:00 AM", value: "05:00" },
        { text: "05:30 AM", value: "05:30" },
        { text: "06:00 AM", value: "06:00" },
        { text: "06:30 AM", value: "06:30" },
      ],
      scheduledb: null,
      schedule: [
        {
          menu: false,

          active: false,
          ochoActive: false,
          date: "",
          time: "",
          deleted: false,
        },
      ],
    };
  },
  watch: {},
  computed: {
    ...mapState(["business", "user", "selectedEvent"]),
  },
  methods: {
    ...mapActions(["_searchTerm", "Alert_", "visibleSearch"]),
    add() {
      this.schedule.push({
        menu: false,
        active: false,
        // ochoActive: false,
        deleted: false,
        date: "",
        time: "",
      });
    },
    remove(item, index) {
      if (item[`.key`]) {
        db.collection("events")
          .doc(this.selectedEvent[`.key`])
          .collection("dates")
          .doc(item[`.key`])
          .update({
            deleted: true,
            active: false,
            deletedAt: new Date(),
            deletedBy: this.user[`.key`],
          })
          .then(() => {
           // console.debug(i);
            this.schedule.splice(index, 1);
            this.Alert_({
              text: "Horario eliminado",
              timeout: 2000,
              btn_closed: true,
              icon: false,
              iconType: "mdi-check",
              type: "info",
            });
          })
          .catch(() => {
            this.Alert_({
              text: "Ocurrio un error al eliminar",
              timeout: 2000,
              btn_closed: true,
              icon: false,
              iconType: "mdi-check",
              type: "error",
            });
          });
      } else {
        var i = this.schedule.indexOf(item);
       // console.debug(i);
        this.schedule.splice(index, 1);
        this.Alert_({
          text: "Horario eliminado",
          timeout: 2000,
          btn_closed: true,
          icon: false,
          iconType: "mdi-check",
          type: "info",
        });
      }
    },
    async addExcelfn() {
      if (this.dataXLSValid.length > 0) {
        for (let i = 0; i < this.dataXLSValid.length; i++) {
          const element = this.dataXLSValid[i];
         // console.debug(element);
          await this.schedule.push(element);
        }
      }

      this.dataValidDialog = false;
      this.dataXLSValid = [];
      this.dataXLSInvalid = [];
      this.Alert_({
        text: "Horarios Agregados correctamente",
        timeout: 2000,
        btn_closed: true,
        icon: false,
        iconType: "mdi-check",
        type: "success",
      });
    },
    cancelXLSValid() {
      this.dataValidDialog = false;
      this.dataXLSValid = [];
      this.dataXLSInvalid = [];
    },
    async save() {
      this.btnLoading = true;
      this.$refs.form.validate();

      if (this.$refs.form.validate()) {
        await this.addSchedulesDB()
          .then(async(response) => {
           // console.debug(response);
            this.Alert_({
              text: "Horarios actualizados",
              timeout: 2000,
              btn_closed: true,
              icon: false,
              iconType: "mdi-check",
              type: "success",

            });
             await this.refreshSchedule();
            // this.schedule = JSON.parse(JSON.stringify(this.scheduledb));
            this.btnLoading = false;

            setTimeout(() => {
              if (this.contSchedule > 0) {
                this.Alert_({
                  text: "Algunos horarios ya existían y no fueron agregados",
                  timeout: 2000,
                  btn_closed: true,
                  icon: false,
                  iconType: "mdi-check",
                  type: "error",
                });
              }
              this.contSchedule = 0;
            }, 3000);
          })
          .catch((erro) => {
           // console.debug(erro);
            this.btnLoading = false;
          });
      } else {
        this.Alert_({
          text: "LLena todos los campos",
          timeout: 2000,
          btn_closed: true,
          icon: false,
          iconType: "mdi-check",
          type: "error",
        });
        this.btnLoading = false;
      }
    },

    addSchedulesDB() {
      this.contSchedule = 0;
      return new Promise(async (resolve, reject) => {
        if (this.schedule.length > 0) {
          const cofig = db
            .collection("events")
            .doc(this.selectedEvent[`.key`])
            .collection("dates");

          for (let i = 0; i < this.schedule.length; i++) {
            const element = this.schedule[i];

            if (element[`.key`]) {
              let dataUpdate = {
                active: element.active,
                date: element.date,
                time: element.time,
                business: this.business[`.key`],
                modifiedAt: new Date(),
                modifiedBy: this.user[`.key`],
                deleted: element.deleted,
              };
              await cofig.doc(element[`.key`]).update(dataUpdate);
            } else {
              let data = {
                active: element.active,
                date: element.date,
                time: element.time,
                business: this.business[`.key`],
                createdAt: new Date(),
                createdBy: this.user[`.key`],
                deleted: element.deleted,
              };

              await cofig
                .where("date", "==", element.date)
                .where("time", "==", element.time)
                .get()
                .then(async (resp) => {
                  //// console.debug(resp.docs.length);
                  if (resp.docs.length === 0) {
                   // console.debug("si");
                    await cofig.add(data);
                  } else {
                   // console.debug("no");
                    this.contSchedule += 1;
                  }
                });
            }
          }
          resolve(this.scheduledb);
        }
      });
    },
    uploadFile(e) {
     // console.debug(e);
      this.matchDialog = true;
    },
    processFile(e) {
      let time = e.find((item) => item.value == "time");
      let timePosition = time.selectedColumn;

      let date = e.find((item) => item.value == "date");
      let datePosition = date.selectedColumn;

     console.log(date, time);
     console.log(timePosition);

      //   this.users = [];

      readXlsxFile(this.File, { sheet: 1 }).then(async (rows) => {
        for (let i = 1; i < rows.length; i++) {
          const element = rows[i];

         // console.debug(element);

          let obj = {
            menu: false,
            active: false,
            // ochoActive: false,
            deleted: false,
            date: element[datePosition],
            time: element[timePosition],
          };

          if (
            element[datePosition] >= this.selectedEvent.dateStart &&
            element[datePosition] <= this.selectedEvent.dateEnd
          ) {
           // console.debug("si");
            this.dataXLSValid.push(obj);
          } else {
           // console.debug("no");
            this.dataXLSInvalid.push(obj);
          }

          // this.selectedEvent.dateStart
          // this.selectedEvent.dateEnd
        }

        this.dataValidDialog = true;

        this.matchDialog = false;
        this.dialog = false;

        // document.getElementById("input").value = "";

        // let result = this.item.users.map((item) => {
        //   return item[".key"];
        // });

        // this.item.users = result;
      });
    },
    refreshSchedule() {
      this.schedule = JSON.parse(
        JSON.stringify(
          this.scheduledb.map((element) => {
            let search = this.tickets.find(
              (x) => x.ticketDate == element[`.key`]
            );
           // console.debug(search);
            if (search) {
              element.disabled = true;
            } else {
              element.disabled = false;
            }
            return element;
          })
        )
      );
    },
  },

  async mounted() {
    this.show = false;

    await this.$binding(
      "tickets",
      db
        .collection("oViveTickets")
        .where("event", "==", this.selectedEvent[".key"])
    );

    this._searchTerm("horarios de eventos");
    this.visibleSearch(false);

    await this.$binding(
      "scheduledb",
      db
        .collection("events")
        .doc(this.selectedEvent[`.key`])
        .collection("dates")
        .where("deleted", "==", false)
        .where("business", "==", this.business[`.key`])
        .orderBy("date", "asc")
    );
    if (this.scheduledb.length > 0) {
      await this.refreshSchedule();

      this.show = true;
    } else {
      this.show = true;
    }
  },
};

// const schedules = {
//   active: false,
//   ochoActive: false,
//   date: "",
//   time: "",
//   business: this.business[`.key`],
//   createdAt: new Date(),
//   createdBy: this.user[`.key`],
//   deleted: false,
//   // deletedAt: new Date(),
//   // deletedBy: this.user[`.key`],
//   // modifiedAt: new Date(),
//   // modifiedBy: this.user[`.key`],
// };
</script>

<style lang="scss" scoped>
.schediles-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.loader-- {
  min-width: 100%;
  height: 50vh !important;
}
.theme--dark.v-list {
  background: #282034 !important;
  color: #ffffff;
}
.v-progress-circular {
  margin: 1rem;
}
.UlStyle {
  border-radius: 10px;
  padding: 10px;
  li {
    list-style: none;
  }
}
input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
}
.position-absolute {
}
</style>
