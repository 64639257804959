<template>
  <v-card>
    <v-card-title>
      <v-btn
        style="right: 10px; top: 10px"
        icon
        color="primary"
        fab
        small
        absolute
        @click="$emit('closed')"
      >
        <v-icon dark>fa-times</v-icon>
      </v-btn>
     Estructura de datos
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text class="pt-4">
      <v-row class="mt-5">
        <v-col cols="12" sm="4" md="4">
          <span class="font-weight-bold"> Campos de usuario </span>
          <v-divider></v-divider>
        </v-col>
        <v-col cols="12" sm="8" md="8">
          <span class="font-weight-bold">
            Columnas en el archivo seleccionado
          </span>
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <v-row v-for="(item, index) of requiredFields" :key="index">
        <v-col cols="12" sm="4" md="4">
          <p class="mt-5">{{ item.name }}</p>
        </v-col>
        <v-col cols="12" sm="8" md="8">
          <v-select
         style="color:#000"
            v-model="item.selectedColumn"
            :items="fileColumns"
            label="Seleccionar columna correspondiente"
            item-text="text"
            item-value="value"
            aria-autocomplete="false"
            filled
            :loading="loading"
            rounded
            :allow-overflow="false"
            clearable
          ></v-select>
        </v-col>
      </v-row>
    </v-card-text>

    <v-divider></v-divider>
    <v-card-actions class="actions-btn">
      <v-btn color="primary" style="border-radius: 10px" @click="success" >Aceptar</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import readXlsxFile from "read-excel-file";
export default {
  name: "matchFile",
  props: ["file"],
  data() {
    return {
      requiredFields: [
        {
          name: "Fecha",
          value: "date",
          selectedColumn: 0,
        },
        {
          name: "Hora",
          value: "time",
          selectedColumn: 1,
        },
      ],
      fileColumns: null,
      loading: false,
    };
  },
  methods: {
    success() {
      this.loading = true;
      this.$emit("success", this.requiredFields);
    },
  },
  mounted() {
    readXlsxFile(this.file, { sheet: 1 }).then((rows) => {
     // console.debug(rows);
      this.fileColumns = rows[0].map((item, index) => {
        if (item) {
          return {
            value: index,
            text: item,
          };
        }
      });

      this.fileColumns.filter((item) => item != "undefined" || item);
      this.loading = false;
    });
  },
};
</script>

<style lang="scss" scoped>

</style>